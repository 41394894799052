<template>
  <div class="headerPage">
    <div class="header">
      <van-icon name="arrow-left" class="arrow_left" alt="" @click="goBack" />
      <span>领取优惠券</span>
      <div style="width:21px"></div>
    </div>
    <div class="top">
      <img src="@/static/couponTop.png" alt="">
    </div>
    <van-loading v-if="loading" style="text-align: center;margin-top:50px;" type="spinner" />
    <div v-else>
      <div v-if="!msg ">
        <div class="coupon_box">
          <img src="@/static/coupon.png" alt="">
          <div class="coupon_left">
            <div>
              <p v-if="coupon_obj.discount_radio" style="marginBottom:2px">
                <span class="num">{{ coupon_obj.discount_radio_name.slice(0,coupon_obj.discount_radio_name.length-1) }}</span>
                <span>折</span>
              </p>
              <p v-else style="marginBottom:2px">
                <span class="num">{{ coupon_obj.money }}</span><span>元</span>
              </p>
              <!-- <p v-else class="top">¥ <span class="number">{{ coupon_obj.money }}</span></p> -->
              <p style="font-family: PingFangSC-Medium;font-weight: 500;marginTop:2px">{{ coupon_obj.left_name }}</p>
              <p style="font-family: PingFangSC-Medium;font-weight: 500;marginTop:5px;fontSize:12px;color:#333;">仅剩<span style="color:#ee2e2e;">{{ coupon_obj.remain_num }}</span>张</p>
            </div>
          </div>
          <div class="coupon_right">
            <p class="coupon_name">{{ coupon_obj.right_name }}</p>
            <p class="coupon_time">自领取后{{ coupon_obj.expire_hours }}小时有效</p>
            <!-- <p class="coupon_time">自领取之日起{{ coupon_obj.expire_days }}日有效</p> -->
          </div>
        </div>
        <div class="get_box">
          <div class="phone">
            <div class="myPhone">
              <span>+86</span>
              <input v-model="mobile" placeholder="输入您的手机号，领取优惠券" type="number">
            </div>
          </div>
          <div>
            <van-button class="input__" round block color="linear-gradient(133deg, #F76262 0%, #EE2E2E 100%)" @click="GetItNow">
              领取
            </van-button>
          </div>
          <div class="foot">
            <p>优惠券属于稀缺资源，若不需要请勿领取</p>
          </div>
        </div>
      </div>
      <div v-else class="nothingness">
        <img src="https://newoss.zhulong.com/forum/202306/14/15/1686728350641932.png" alt="">
        <p>{{ msg+'~' }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getItNow, get_ones_yhq } from '@/api/my_user.js'
import { Toast } from 'vant'
export default {
  components: {
  },
  data() {
    return {
      title_msg: '领取优惠卷',
      mobile: '',
      coupon_obj: {},
      coupon_id: this.$route.query.id || '',
      share_uid: this.$route.query.uid || '',
      loading: false,
      msg: ''
    }
  },
  mounted() {
    this.coupon_id = this.$route.query.id || '',
    this.share_uid = this.$route.query.uid || '',
    this.getInfo()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    getInfo() {
      var params = {
        id: this.coupon_id,
        uid: this.share_uid
      }
      this.loading = true
      get_ones_yhq(params).then(res => {
        if (res.errNo == 0) {
          this.coupon_obj = res.result
        } else {
          this.msg = res.msg
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    GetItNow() {
      if (this.mobile === '') {
        Toast('手机号不能为空')
        return false
      }
      var params = {
        coupon_id: this.coupon_id,
        uid: this.share_uid,
        mobile: this.mobile
      }
      getItNow(params).then(res => {
        if (res.errNo !== 0) {
          Toast(res.msg)
        } else {
          Toast('领取成功')
          this.$router.push({
            path: '/index/coupon/ReceiveResults',
            query: {
              status: 1,
              id: this.coupon_id,
              share_uid: this.share_uid
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.my_couponMainright {
  flex: 1;
  height: 100%;
  // background: chartreuse;
  p:nth-child(1) {
    font-size: 30px;
    color: #333333;
    // text-align: center;
    margin: 0 auto;
    width: 82%;
    padding: 25px 0 0 0;
  }
  p:nth-child(2) {
    width: 82%;
    margin: 0 auto;
    padding: 15px 0 0 0;
    color: #333333;
    span {
      padding-right: 20px;
      color: #ee2e2e;
    }
  }
}
.my_couponMainLeft {
  width: 180px;
  height: 100%;
  // background: #e62929;
  p:nth-child(1) {
    color: #fff;
    font-size: 50px;
    text-align: center;
    padding: 40px 0 0 0;
    span {
      font-size: 30px;
    }
  }
  p:nth-child(2) {
    color: #fff;
    padding-top: 20px;
    text-align: center;
  }
}
.my_btn button {
  width: 684px;
  height: 90px;
  margin: 0 auto;
  display: block;
  margin-top: 40px;
  color: #fff;
  background: #e62929;
  border-radius: 13px;
  font-size: 30px;
  letter-spacing: 5px;
}
.foot p {
  font-size: 28px;
  text-align: center;
  padding-top: 10px;
  color: #999999;
}
.myPhone {
  width: 100%;
  height: 86px;
  border: 3px solid #ddd;
  margin: 0 auto;
  border-radius: 13px;
  background-color: #fff;
  display: flex;
  align-items: center;
  span {
    line-height: 86px;
    padding-left: 40px;
    padding-right: 40px;
    border-right: 2px solid #f5f5f5;
  }
  input {
    height: 86px;
    padding: 0 0 0 40px;
    width: 500px;
    color: #333;
    font-size: 28px;
  }
}
.my_couponMain {
  display: flex;
  width: 684px;
  height: 181px;
  margin: 0 auto;
  background: url("../../static/images/my_index/222.png") no-repeat;
  background-size: 100% 100%;
  box-shadow: 0px 3px 35px #e1e1e1;
  margin-top: 30px;
}
.phone {
  width: 100%;
  height: 95px;
  margin: 0px 0 40px 0;
}
.all {
  background: url("../../static/images/my_index/givesbg.png") no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  width: 100%;
  // position: relative;
  // bottom: 30px;
  // overflow: hidden;
}
.my_headers {
  border-bottom: 1px solid #e1e1e1;
}
.title {
  width: 100%;
  height: 185px;
  p:nth-child(1) {
    font-size: 30px;
    text-align: center;
    padding-top: 64px;
    letter-spacing: 2px;
    color: #494949;
  }
  p:nth-child(2) {
    font-size: 35px;
    text-align: center;
    padding-top: 30px;
    color: #494949;
    letter-spacing: 3px;
    span {
      color: #ee2e2e;
      padding: 0 5px;
    }
  }
}

/deep/.van-dropdown-menu__bar{
  box-shadow: 0 0 0;
  height: 48px;
}
.header{
  width: 100%;
  padding: 0 40px;
  position: absolute;
  top:100px;
  color:#fff;
  font-size: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  .arrow_left{
      font-size: 42px;
    }
}
.coupon_box{
  width: 100%;
  position: relative;
  margin-top:-240px;
  img{
    width: 100%;
  }
  .coupon_left{
    position: absolute;
    // left:120px;
    width: 44%;
    height: 100%;
    line-height: 1;
    top:0px;
    font-size: 30px;
    color: #EE2E2E;
    letter-spacing: 1.33px;
    text-align: center;
    font-weight: 500;
    div{
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .num{
      font-family: "Impact" !important;
      font-size: 86px;
      color: #EE2E2E;
      text-align: left;
      font-weight: 900;
    }
    .top{
        font-size: 30px;
        .number{
          font-size: 86px;
        }
      }
  }
  .coupon_right{
    width: 58%;
    height: 100%;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    position: absolute;
    right:0px;
    top:0px;
    font-size: 30px;
    color: #333333;
    letter-spacing: 1.33px;
    text-align: center;
    font-weight: 500;
    text-align: left;
    .coupon_name{
      width: 60%;
      height:80px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      margin-bottom:5px;
    }
    .coupon_time{
      margin-top:5px;
      text-align: left;
      font-size: 28px;
      color: #999999;
      letter-spacing: 0;
      // text-align: center;
      font-weight: 400;
    }
  }
}
.get_box{
  padding:0 40px;
  .tip{
    font-size: 40px;
    color: #333333;
    letter-spacing: 0;
    text-align: center;
    font-weight: 700;
    margin-bottom:23px;
  }
}
.phone_box{
  height: 90px;
  padding:0 40px;
  background: #FFFFFF;
  border-radius: 45px;
  line-height: 90px;
  margin-bottom:30px;
  display: flex;
  align-items: center;
  .area_num{
    width: 160px;
    height: 48px;
    padding:0 30px 0 0;

    box-sizing: border-box;
    font-family: PingFangSC-Medium;
    font-size: 32px;
    color: #333333;
    letter-spacing: 0;
    font-weight: 500;
    border-right: 1px solid #999999;
  }
}
.headerPage{
  // position: fixed;
  // top: 0;
  // left: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 1000;
  background: #F0F0F0;
}
.page_header{
    height: 80px;
    line-height: 80px;
    border-bottom: 1px solid #ddd;
    padding: 0 25px 0 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title{
        font-size: 34px;
        // font-weight: 600;
    }
    .arrow_left{
      width: 24px;
      height: 42px;
    }
    .more_icon{
      width: 42px;
      height: 30px;
      // margin-right: 80px;
      margin-right: 0px;
    }
}
.top{
  img{
    width: 100%;
    // height: 453px;
  }
}
.input__{
  height: 90px;
  line-height: 90px;
  font-size: 32px;
  }

.nothingness{
  img{
    margin: 0 auto;
    display: block;
  }
  p{
    font-size: 28px;
    text-align: center;
    color: #999;
  }
}
.phone_box {
  /deep/.van-button--normal {
    padding: 10px 10px !important;
  }
  /deep/.van-button--default {
    color: #ee2e2e !important;
    background-color: transparent !important;
    border: none !important;
    height: 40px !important;
    line-height: 40px !important;
  }
  /deep/.van-field__button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
  }
}
</style>
